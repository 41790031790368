
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































.satisfaction-intro {
  line-height: 1.5;
}

.info {
  display: flex;
  padding: 1.5rem;
  background-color: $c-surface-light-accent;
}

.info__icon {
  flex-shrink: 0;
  margin-right: 1.5rem;
  fill: $c-main;
}

.info__text {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 2;
}

::v-deep {
  .question.na {
    .question__headline,
    .question__label {
      opacity: 0.2;
    }
  }
}

.satisfaction-feedback {
  display: flex;
  flex-direction: column;
}

.satisfaction-feedback__intro {
  display: flex;
  align-items: flex-start;
  line-height: 2;
  flex-direction: column;

  @include mq($from: xs) {
    flex-direction: row;
  }
}

.satisfaction-feedback__icon {
  fill: $c-main;
  width: 5.4rem;
  height: auto;
  flex-shrink: 0;
  margin-bottom: 2rem;

  @include mq($from: xs) {
    margin-right: 4rem;
    margin-bottom: 0;
  }
}

.satisfaction-feedback__label {
  line-height: 2;
  cursor: pointer;
}

.textarea {
  background-color: $c-surface-light;
}

.submit {
  align-self: flex-end;

  ::v-deep {
    .curtain__active {
      background-color: $c-surface-dark;
      color: $c-white;
    }
  }
}

.satisfaction-feedback-message {
  font-weight: 600;
  font-size: 2rem;
}
