
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































.question {
  border-bottom: 1px solid currentColor;
}

.question__headline {
  font-size: 1.5rem;
  line-height: 2;
  opacity: 0.4;
  transition: opacity 150ms $easing;
  margin-bottom: 1rem;
}

.question__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.question__label {
  width: 100%;
  font-size: 2rem;
  line-height: 1.5;
  transition: opacity 150ms $easing;
  margin-bottom: 2rem;

  @include mq($from: m) {
    width: col(8, 12);
  }

  @include mq($from: l) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.question__inputs {
  display: flex;
  flex-direction: column;

  @include mq($from: l) {
    margin-left: auto;
  }
}

.question__stars {
  margin-bottom: 2rem;
  display: flex;
  z-index: 1;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  &:hover,
  &.has-value {
    .question__star {
      color: $c-main;
    }
  }

  .question__star {
    transition: color 150ms $easing;
    color: $c-pampas;

    ::v-deep {
      .star__label {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    &:hover,
    &:focus,
    &.checked {
      ~ .question__star {
        color: $c-pampas;
      }
    }
  }

  &.has-value:hover {
    .question__star {
      color: $c-main;

      &:hover,
      &:focus {
        ~ .question__star {
          color: $c-pampas;
        }
      }
    }
  }
}

.question__na {
  display: flex;

  @include mq($from: l) {
    justify-content: flex-end;
  }
}

.question__na__input {
  &:checked + .question__na__label {
    .question__na__svg__bullet {
      fill: $c-main;
      opacity: 1;
    }
  }
}

.question__na__label {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  line-height: 2;
  align-items: center;
  user-select: none;
}

.question__na__svg {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
}

.question__na__svg__stroke {
  opacity: 0.2;
  stroke: $c-black;
}

.question__na__svg__bullet {
  opacity: 0.1;
  fill: $c-black;
  transition: all 150ms $easing;
}
