
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.star {
  position: relative;
  color: inherit;
}

.star__tooltip {
  position: absolute;
  bottom: 150%;
  left: 50%;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: 8px 12px;
  color: $c-black;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: $c-surface-light-accent;
  transform: translate(-50%, -5px);
  transition: all 250ms $easing;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 14px 7px 0 7px;
    border-color: $c-surface-light-accent transparent transparent transparent;
  }
}

.star__label {
  color: inherit;
  cursor: pointer;
  display: block;
}

.star__icon {
  width: 3.2rem;
  height: auto;
  fill: currentColor;
}
